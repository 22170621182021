import {
    Send,
  } from "@mui/icons-material";
  import {
    Alert,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { 
    useEffect,
    useContext,
    useState
  } from "react";
  import { useNavigate, useParams } from "react-router-dom";
  import {  PosizionamentoContext } from "../contexts";
  import { useForm } from "../hooks";
  import ReactMarkdown from "react-markdown";
  import rehypeRaw from "rehype-raw";
  import { TableRowHtml, DialogUbicazioni } from "../components";

  const CAUSALE_ENTRATA = "ent";
  
  const MagLogEntrataUBIDettaglio = (props) => {
    const navigate = useNavigate()
    const { PrgMovimento } = useParams();
    const [customCol, setCustomCol] = useState([]);
    const [dialogUbicazioniOpen, setDialogUbicazioniOpen] = useState(false);
  
    const inputRef = React.useRef();
  
    const  {
        state : {
            MovimentoColumns,
            movimento,
            infoMovimento,
        },

        getMagUBIMovimDataEntrata,
        //postRegistraMovimentoEntrata
        getDeterminaBarcode,
        putRegistraRigaMerceEntrata,
    } = useContext(PosizionamentoContext);

  
    useEffect(() => {
      if (PrgMovimento) {
        getMagUBIMovimDataEntrata(PrgMovimento,  navigate);
      }
    }, []);
  
    const validations = [];
    const {
      values: barcodeForm,
      changeHandler,
      setValues,
    } = useForm(
      {
        Barcode: "",
        Entita : "",
        Articolo : "",
        Ubicazione : "",
        Quantita : 0,
        CommCodice : "",
        GruppoGestioneMag : "",
      },
      validations
    );
  

  
    React.useEffect(() => {
      let colonneDaModificare = [];
      if (MovimentoColumns){
        MovimentoColumns.map((res, i) => {
          colonneDaModificare = [
            ...colonneDaModificare,
            {
              field: res.id,
              headerName: res.label,
              minWidth: res.minWidth,
            },
          ];
        });
        setCustomCol(colonneDaModificare);
      }
    }, [MovimentoColumns]);
  
    const onkeyEnter = (event) => {
      if (event === 13) {
        saveData();
      }
    };
  
    const saveData = () => {  
      //postRegistraMovimentoEntrata(PrgMovimento, barcodeForm.Articolo);
      getDeterminaBarcode(barcodeForm, setDialogUbicazioniOpen, setValues, PrgMovimento)
      console.log("TODO POST: ", PrgMovimento, barcodeForm.Barcode)

      resetArticolo(); 
    }

  
  
    // const saveDataConferma = () => {    
    //   postRegistraMovimentoEntrata(PrgMovimento, barcodeForm.Articolo);
    //   resetArticolo(); 
    //   // setTimeout(() => {
    //   //   inputRef.current.focus();
    //   // }, 100);  
    // }
  
  
    const resetArticolo = () => {    
      setValues({
        Barcode: "",
        Entita : "",
        Articolo : "",
        Ubicazione : "",
        Quantita : 0,
        CommCodice : "",
        GruppoGestioneMag : "",
      });
    }

    const closeDialog = () => {
      setDialogUbicazioniOpen(false);
      resetArticolo()
    }
  
  
    return (
      <>
        <Grid container>
          <Grid
            container
            sx={{
              position: "fixed",
              background: (theme) => theme.palette.background.paper,
            }}
          >
            <TextField
              id="outlined-basic"
              label="Segnacollo"
              variant="outlined"
              placeholder="Inserire il segnacollo"
              fullWidth
              autoFocus
              autoComplete="off"
              inputRef={inputRef}
              onChange={(e) => changeHandler("Barcode", e.target.value)}
              value={barcodeForm.Barcode || ""}
              onKeyPress={(e) => onkeyEnter(e.charCode)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      variant="contained"
                      color="secondary"
                      onClick={() => saveData()}
                    >
                      <Send />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          
    

          <Grid container mt={8}>
            <Grid item container p={1}>
              <Grid item xs={12} md={6} lg={6}>
                  <Alert severity="info" component="span">
                    {
                      <ReactMarkdown
                        children={
                          infoMovimento !== ''
                            ? infoMovimento
                            : ""
                        }
                        rehypePlugins={[rehypeRaw]}
                      ></ReactMarkdown>
                    }{" "}
                  </Alert>
              </Grid>
            </Grid>

              <Grid item xs={12} md={12} lg={12}>
                {customCol?.length > 0 ? (
                  <TableRowHtml customCol={customCol} rows={movimento} />
                ) : (
                  <></>
                )}
              </Grid>  
          </Grid> 

          <DialogUbicazioni  
            isOpen={dialogUbicazioniOpen}
            setIsOpen={setDialogUbicazioniOpen}
            setValues={setValues}
            changeHandler={changeHandler}
            barcodeForm={barcodeForm}
            dialogClose={closeDialog}
            registraRigaMerce={putRegistraRigaMerceEntrata}
            prgMovimento={PrgMovimento}

          /> 
        </Grid>
      </>
    );
  };
  
  export default MagLogEntrataUBIDettaglio;
  